import React, { useCallback, useContext, useEffect, useState } from 'react';
import * as S from './LockInSelectorV4.styles';
import FunnelContext from '../../context/FunnelContext';
import { ProductPrice } from '../../utils/productPrice';
import loadable from '@loadable/component';
import { CSSTransition } from 'react-transition-group';
import { useRef } from 'react';
import { createRef } from 'react';
import { lockIntemplateText } from '../../utils/lockInHelpers';

const SubPopup = loadable(() =>
  import('../PackageSelector/SubPopupLegacy/SubPopupLegacy')
);

const LockInSelector = props => {
  const {
    styles = 'default',
    periods,
    filterBySize = false,
    defaultFilterBySize = 'small',
    faqUrl = '#test'
  } = props;

  const {
    extraObjects,
    setExtraObjects,
    currentCategory,
    setCurrentCategory,
    currentProduct,
    setCurrentProduct,
    currency,
    subscription,
    onetime,
    setTubIndexChecked,
    currentTubIndex,
    setBillingFrequency,
    tag: productSize,
    setTag: setProductSize
  } = useContext(FunnelContext);

  const refs = useRef(periods.map(_ => createRef()));
  const currencySymbol = currency?.symbol || `$`;
  const [currentPeriod, setCurrentPeriod] = useState('1-month');
  const [init, setInit] = useState(false);
  const [showSubPopup, setShowSubPopup] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const ProductData = useCallback(
    (category, filterByTag) => {
      let productData = [];

      if (category === 'subscriptions') {
        productData = subscription?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      } else {
        productData = onetime?.products.map((product, index) => {
          product.index = index;
          return product;
        });
      }

      if (filterBySize) {
        productData = productData?.filter(({ tags }) => {
          return tags.includes(productSize);
        });
      }

      if (filterByTag) {
        productData = productData?.filter(({ tags }) => {
          return tags.includes(filterByTag);
        });
      }

      if (category === 'subscriptions') {
        productData = productData?.filter((product, i) => {
          return currentProduct === `subscription_${i}`;
        });
      } else {
        productData = productData?.filter((product, i) => {
          return currentProduct === `onetime_${i}`;
        });
      }
      const [data] = productData;
      return data;
    },
    [subscription, onetime, currentProduct, filterBySize, productSize]
  );

  const handleClick = useCallback(
    selected => {
      const {
        value = '1-month',
        billingFrequency = null,
        category = 'subscriptions',
        productData: { index: productDataIndex = 0 },
        overrideBumpOfferCategory = false,
        bumpOfferCategory = 'subscriptions'
      } = selected;

      setCurrentPeriod(value);
      setBillingFrequency(billingFrequency);
      setCurrentCategory(category);
      setTubIndexChecked(true);
      setExtraObjects(
        Object.assign(extraObjects, {
          sub_lock_in_enabled: true,
          sub_lock_in_schedule: value,
          tub_index_override: productDataIndex,
          overrideBumpOfferCategory,
          bumpOfferCategory
        })
      );
      setCurrentProduct(
        `${
          category === 'subscriptions' ? 'subscription' : 'onetime'
        }_${currentTubIndex}`
      );
    },
    [
      extraObjects,
      setExtraObjects,
      setCurrentPeriod,
      setTubIndexChecked,
      setCurrentCategory,
      setCurrentProduct,
      currentTubIndex,
      setBillingFrequency
    ]
  );

  useEffect(() => {
    if (!init) {
      setInit(true);
      setProductSize(defaultFilterBySize);

      setCurrentPeriod(periods[0]?.value ?? '1-month');

      periods.forEach((item, index) => {
        if (item.selected) {
          handleClick(item);
          setActiveIndex(index);
        }
      });
    }
    const productData = ProductData(currentCategory, currentPeriod);
    if (productData) {
      setTubIndexChecked(true);
      setExtraObjects(
        Object.assign(extraObjects, {
          tub_index_override: productData.index
        })
      );
    }
  }, [
    init,
    setInit,
    periods,
    extraObjects,
    setExtraObjects,
    setCurrentPeriod,
    currentCategory,
    currentProduct,
    currentPeriod,
    ProductData,
    setTubIndexChecked,
    setCurrentCategory,
    setCurrentProduct,
    currentTubIndex,
    setBillingFrequency,
    setProductSize,
    defaultFilterBySize,
    handleClick
  ]);

  const duration = 500;
  const onEnter = node => {
    node.style.height = `${node.dataset.height}px`;
  };
  const onEntering = node => {
    node.style.height = `${node.dataset.height}px`;
  };
  const onEntered = node => {
    node.style.height = `${node.dataset.height}px`;
  };
  const onExit = node => {
    node.style.height = `0px`;
  };
  const onExiting = node => {
    node.style.height = `0px`;
  };
  const onExited = node => {
    node.style.height = `0px`;
  };

  return (
    <S.Container className={styles}>
      <S.ListContainer>
        {periods.map((item, index) => {
          const {
            isMathFloorPerMonth,
            hasBorder,
            category = 'subscriptions',
            chewsPerTub = 30,
            value: lockin = '1-month',
            periodLength = 1,
            elementHeight = 0,
            showMostPopularTag = false,
            showBestValueTag = false,
            titleDiscountBgColor = '#d73375',
            titleDiscountBgHoverColor = '#d73375'
          } = item;

          const productId = `${
            category === 'subscriptions' ? 'subscription' : 'onetime'
          }_${currentTubIndex}`;

          const isActive = activeIndex === index;

          const applyTemplate = text =>
            lockIntemplateText({
              text,
              chewsPerTub,
              lockin,
              periodLength,
              category,
              productId,
              isMathFloorPerMonth,
              filterBySize,
              productSize,
              currencySymbol
            });

          item.productData = ProductPrice({
            type: `data`,
            currentCategory: category,
            currentProduct: productId,
            filterByTag: lockin
          });

          return (
            <S.ListItem
              onClick={() => {
                handleClick(item);
                setActiveIndex(index);
              }}
              key={index}
              className={`${isActive ? 'active' : ''} ${
                hasBorder ? `has-border` : ``
              } ${styles}`}
              ref={refs[index]}
            >
              <S.Row>
                <S.InputRadio
                  className={`${isActive ? 'active' : ''}`}
                ></S.InputRadio>
                <S.Left className="title">
                  <div className="bold line0">{item.title}</div>
                  {showMostPopularTag && (
                    <>
                      <S.MostPopularTag className="mobile">
                        Most Popular
                      </S.MostPopularTag>
                    </>
                  )}
                  {showBestValueTag && (
                    <>
                      <S.BestValueTag className="mobile">
                        Best Value
                      </S.BestValueTag>
                    </>
                  )}
                  <S.Line1
                    className={`${isActive ? 'active' : ''}`}
                    dangerouslySetInnerHTML={{
                      __html: applyTemplate(item.line1)
                    }}
                  />
                  <S.Line2
                    className={`${isActive ? 'active' : ''}`}
                    dangerouslySetInnerHTML={{
                      __html: applyTemplate(item.line2)
                    }}
                  />
                </S.Left>
                <S.Right>
                  {showMostPopularTag && (
                    <>
                      <S.MostPopularTag>Most Popular</S.MostPopularTag>
                    </>
                  )}
                  {showBestValueTag && (
                    <>
                      <S.BestValueTag>Best Value</S.BestValueTag>
                    </>
                  )}
                  {item.titleDiscount && (
                    <S.TitleDiscount
                      bgColor={titleDiscountBgColor}
                      bgHoverColor={titleDiscountBgHoverColor}
                      className={`discount ${
                        isActive ? 'active' : ''
                      } ${category}`}
                    >
                      <div>{item.titleDiscount}</div>
                    </S.TitleDiscount>
                  )}
                </S.Right>
              </S.Row>
              <CSSTransition
                in={isActive}
                timeout={duration}
                onEnter={onEnter}
                onEntering={onEntering}
                onEntered={onEntered}
                onExit={onExit}
                onExiting={onExiting}
                onExited={onExited}
              >
                <S.Row2
                  ref={refs.current[index]}
                  className={`${item.showSubcriptionBenefits ? `` : `hidden`} ${
                    isActive ? 'active' : ''
                  }`}
                  data-height={elementHeight}
                  style={{ height: isActive ? `${elementHeight}px` : `0px` }}
                >
                  <S.SubBenefits>
                    {periodLength > 1 && (
                      <S.SubBenefitItem>
                        <img src="/images/check-green.svg" alt="item 1" />
                        <span>
                          <strong>EXTRA SAVINGS</strong> on a 3 month plan
                        </span>
                      </S.SubBenefitItem>
                    )}
                    <S.SubBenefitItem>
                      <img src="/images/check-green.svg" alt="item 1" />
                      <span>
                        <strong>FREE SHIPPING</strong> every single month
                      </span>
                    </S.SubBenefitItem>
                    <S.SubBenefitItem>
                      <img src="/images/check-green.svg" alt="item 1" />
                      <span>
                        <strong>FREE BEEF JERKY</strong> with first delivery
                        <sup>*</sup>
                      </span>
                    </S.SubBenefitItem>
                    <S.SubBenefitItem>
                      <img src="/images/check-green.svg" alt="item 1" />
                      <span>
                        <strong>CANCEL WITH EASE</strong>{' '}
                        <S.CancelAnytime onClick={() => setShowSubPopup(true)}>
                          see more details
                        </S.CancelAnytime>
                      </span>
                    </S.SubBenefitItem>
                  </S.SubBenefits>
                </S.Row2>
              </CSSTransition>
            </S.ListItem>
          );
        })}
      </S.ListContainer>
      <SubPopup
        show={showSubPopup}
        setShowSubPopup={setShowSubPopup}
        faqURL={faqUrl}
        newWindow={false}
      />
    </S.Container>
  );
};

export default LockInSelector;
