import styled from 'styled-components';
import { queries } from '../../utils/mediaQueries';

export const Container = styled.div`
  &.default {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #ccd2e3;
    background: #fff;
  }

  &.pdp2023 {
  }
`;

export const ListContainer = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
`;

export const ListItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 16px;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  min-height: 60px;
  border: 1px solid transparent;
  background: #f2f4f8;
  overflow: hidden;
  &.active {
    background: radial-gradient(
      344.85% 141.42% at 100% 100%,
      #002084 0%,
      #001c72 100%
    );
    color: #fff;
  }

  &.pdp2023 {
    border-radius: 8px;
  }
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  position: relative;
  gap: 8px;
  width: 100%;
  &.hidden {
    display: none;
  }
`;

export const Row2 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative;
  gap: 8px;
  width: 100%;
  padding-left: 30px;
  transition: height 500ms;
  &.enter-active {
    overflow: hidden;
  }
  &.hidden {
    display: none;
  }
  & > .shipped {
    opacity: 0;
  }
  &.enter-done > .shipped {
    opacity: 1 !important;
  }
`;

export const Left = styled.div`
  position: relative;
  & .bold {
    font-weight: bold;
    &.line0 {
        margin-bottom: 5px;
      }
  }
  
  &.title {
    display: block;
    font-size: 18px;
    line-height: 21px;
    & .discount {
      color: #bc2e3e;
    }
    
  }
  & .subtitle { 
    font-family: "Neuzeit Grotesk - Bold",sans-serif;
    margin-left: 3px
    margin-top: 3px;
    font-size: 18px;
    & > span {
      font-family: "Neuzeit Grotesk",sans-serif;
      font-size: 14px;
    }
    @media (max-width: 320px) {
      font-size: 12px;
    }
  }
  & .line1, 
  & .line2,
  & .line3 {
    display: block;
    font-size: 14px;
    line-height: 19.6px;
    margin-bottom: 5px;
  }
  & .strike {
    text-decoration: line-through;
  }
`;

export const Right = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 16px;
  & .discount {
    display: flex;
    flex-direction: row-reverse;
    margin-left: auto;
    & > div {
      display: inline-block;
      color: #fff;
      padding: 5px 8px;
      text-align: center;
      border-radius: 4px;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
      text-align: right;
    }
    &.onetime > div {
      border: 1px solid #001c72;
      color: #001c72;
    }
    &.active.onetime > div {
      border: 1px solid #fff;
      color: #fff;
    }
  }
  & .bold {
    font-weight: bold;
    font-size: 18px;
    @media ${queries.md} {
      font-size: 16px;
    }
    @media (max-width: 320px) {
      font-size: 14px;
    }
  }
  & .price-per-day {
    color: #bc2e3e;
  }
  & .price-per-month {
    text-align: right;
    min-width: 100px;
  }
  @media ${queries.md} {
    font-size: 14px;
    width: 40%;
  }
  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

export const InputRadio = styled.div`
  width: 22px;
  height: 22px;
  border: 2px solid #001c72;
  display: block;
  border-radius: 100%;
  display: flex;
  margin-top: 0px;
  margin-bottom: auto;
  &.active {
    width: 22px;
    height: 22px;
    border: none;
    background: #fff;
    &:before {
      content: ' ';
      width: 16px;
      height: 16px;
      display: block;
      background-color: #001c72;
      border-radius: 100px;
      margin: 3px;
    }
  }
`;

export const CancelAnytime = styled.span`
  text-decoration: underline;
`;

export const SubBenefits = styled.div`
  font-size: 14px;
  padding-top: 8px;
  margin-top: 16px;
  border-top: 1px solid #67a;
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
`;
export const SubBenefitItem = styled.div`
  position: relative;
  display: flex;
  gap: 4px;

  & > strong {
    font-family: 'Neuzeit Grotesk - Bold', sans-serif;
    color: #fff;
  }
  & > span {
    color: #e6e8f1;
    display: block;
    margin: auto 0;
    & > sup {
      position: absolute;
      margin-top: -3px;
    }
    & > strong {
      color: #fff;
    }
  }
`;

export const ShippedMonthly = styled.div`
  font-size: 14px;
  &.hidden {
    display: none;
  }
`;

export const Line1 = styled.div`
  font-size: 18px;
  & > span {
    font-size: 14px;
    color: #67a;
  }
  &.active > span {
    color: #ccd2e3;
  }
`;

export const Line2 = styled.div`
  font-size: 14px;
  color: #33498e;
  &.active {
    color: #e6e8f1;
  }
`;

export const MostPopularTag = styled.div`
  color: #d73375;
  font-size: 14px;
  font-family: Neuzeit Grotesk;
  font-weight: bold;
  display: flex;
  gap: 4px;
  margin: auto;
  &:before {
    content: '';
    position: relative;
    display: block;
    background: url(/images/red-heart.svg) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
  @media (max-width: 560px) {
    display: none;
    &.mobile {
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px;
      &:before {
        margin-top: -1px;
      }
    }
  }
  @media (min-width: 560px) {
    &.mobile {
      display: none;
    }
  }
`;
export const BestValueTag = styled.div`
  color: #00b880;
  font-size: 14px;
  font-family: Neuzeit Grotesk;
  font-weight: bold;
  display: flex;
  gap: 4px;
  margin: auto;
  &:before {
    content: '';
    position: relative;
    display: block;
    background: url(/images/green-tag.svg) no-repeat;
    width: 16px;
    height: 16px;
    margin-top: -2px;
  }
  @media (max-width: 560px) {
    display: none;
    &.mobile {
      display: flex;
      padding-top: 8px;
      padding-bottom: 8px;
      &:before {
        margin-top: -1px;
      }
    }
  }
  @media (min-width: 560px) {
    &.mobile {
      display: none;
    }
  }
`;

export const TitleDiscount = styled.div`
  & > div {
    ${({ bgColor = '#d73375' }) => `
      background-color: ${bgColor}!important;
    `}
  }
  &.active > div {
    ${({ bgHoverColor = '#d73375' }) => `
      background-color: ${bgHoverColor}!important;
    `}
  }
`;
